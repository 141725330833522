/*
 * @Author: wangliang
 * @Date: 2023-08-16 14:25:19
 * @LastEditTime: 2024-09-19 09:56:23
 * @LastEditors: wangliang
 * @Description: 页面路由配置
 */
import React, { lazy, useState, useEffect } from "react";
import { useRoutes } from 'react-router-dom';
import { Spin } from 'antd'
import { Skeleton } from 'antd-mobile'
const Page404 = lazy(() => import("@/pages/page404"))
const Home = lazy(() => import("@/pages/home"))
const Chat = lazy(() => import("@/pages/chat"))
const PrivacyTerms = lazy(() => import("@/pages/login/protocol/privacy-terms"))
const UserAgreement = lazy(() => import("@/pages/login/protocol/user-agreement"))
const FilePreview = lazy(() => import("@/pages/file-preview"))
const Transit = lazy(() => import("@/pages/login/transit"))

const MobileChat = lazy(() => import("@/pages/mobile/chat"))
const ThirdPartLogin = lazy(() => import("@/pages/third-part-login"))
const AppRouter = () => {

    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize(); // 初始化时检测一次
        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    const loadingElement =  <div className="page-loading">
        {
            isMobile ? <>
                <Skeleton.Title animated />
                <Skeleton.Paragraph lineCount={15} animated />
            </> : <Spin />
        }
    </div>

    const routes = [
        {
            path: '/',
            element: <React.Suspense fallback={loadingElement}>
                <Home />
            </React.Suspense>,
            meta: {
                title: '首页',
            },
        },
        {
            path: '/chat',
            element: <React.Suspense fallback={loadingElement}>
                {isMobile ? <MobileChat/> : <Chat />}
            </React.Suspense>,
            meta: {
                title: '问答页',
            },
        },
        {
            path: '/login',
            element: <React.Suspense fallback={loadingElement}>
                <Home />
            </React.Suspense>,
            meta: {
                title: '登录',
                noLogin: true,
                hideMenu: true
            }
        },
        {
            path: '/user-agreement',
            element: <React.Suspense fallback={loadingElement}>
                <UserAgreement />
            </React.Suspense>,
            meta: {
                title: '用户协议',
            }
        },
        {
            path: '/privacy-terms',
            element: <React.Suspense fallback={loadingElement}>
                <PrivacyTerms />
            </React.Suspense>,
            meta: {
                title: '用户协议',
            }
        },
        {
            path: '/transit',
            element: <React.Suspense fallback={loadingElement}>
                <Transit />
            </React.Suspense>,
            meta: {
                title: '微信扫码登录成功',
            }
        },
        {
            path: '/file-preview',
            element: <React.Suspense fallback={loadingElement}>
                <FilePreview />
            </React.Suspense>,
            meta: {
                title: '文件预览',
            }
        },
        {
            path: '/third-part-login',
            element: <React.Suspense fallback={loadingElement}>
                <ThirdPartLogin />
            </React.Suspense>,
            meta: {
                title: '第三方登录',
            }
        },
        {
            path: '*',
            element: <React.Suspense><Page404 /></React.Suspense>,
            meta: {
                title: '404',
                noLogin: true,
                hideMenu: true
            }
        },
    ];
    return (
        <div className="page-content">
            {useRoutes(routes)}
        </div>
        
    );
};

export default AppRouter;